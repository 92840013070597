.u-section {
    
  padding-left: 1.25rem;
  padding-right: 1.25rem;

    &__inner {
        position: relative;
        width: 90%;
        max-width: $max-content-width;
        margin: auto;

        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }

    &--full-size {
    
        padding-left: 0;
        padding-right: 0;

        .u-section__inner {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }

    &__headline {
        text-align: center;
        padding: 2% 0;
    }

    &__columns {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.25rem;

        .u-section__column {
            width: 100%;
        }

        &--count-2 {
            .u-section__column {
                @include media-breakpoint-up(md) {
                    width: 50%;
                }
            }
        }

        &--count-3 {
            .u-section__column {
                @include media-breakpoint-up(lg) {
                    width: 33%;
                }
            }
        }

        &--count-4 {
            .u-section__column {
                @include media-breakpoint-up(md) {
                    width: 50%;
                }
                @include media-breakpoint-up(xl) {
                    width: 25%;
                }
            }
        }
    }

    &__column {
        padding: 0 1.25rem;
        width: 100%;
    }

    &--style-white {
        background: $c-white;
        color: $c-black;
    }

    &--style-primary {
        background: $c-primary;
        color: $c-black;
    }

    .pdfemb-viewer {
        max-width: 100%;
    }
}

/**
 * SPACES
 */

.u-section {
    // force non collapsing margin
    overflow: auto;
}

.u-section--style-white .u-section__inner > .u-section__headline:first-child,
.u-section--style-primary .u-section__inner > .u-section__headline:first-child {
    margin-top: 3.375rem;
}

.u-section--style-white .u-section__inner > .u-section__columns:last-child,
.u-section--style-primary .u-section__inner > .u-section__columns:last-child {
    margin-bottom: 3.375rem; 
}

.u-section {
    .u-text,
    .u-download,
    .u-teaser,
    .u-event-teaser {
        margin-top: 4rem;
    }
}

.u-section:first-child .u-section__column--one {
    .u-headline:first-child,
    .u-text:first-child,
    .u-download:first-child,
    .u-teaser:first-child,
    .u-event-teaser:first-child,
    .u-accordion:first-child,
    .u-warning:first-child,
    .u-vimeo-player:first-child {
        margin-top: 5rem;
    }
}

.u-section__columns--count-1 {
    margin: 0;

    .u-section__column {
        padding: 0;
    }
}