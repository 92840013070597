@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(100%)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    100% {
        opacity: 0;
        transform: translateX(100%)
    }
}

@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: translateY(-30%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeOutTop {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    100% {
        opacity: 0;
        transform: translateY(-30%)
    }
}

@keyframes fadeInBottom {
    0% {
        opacity: 0;
        transform: translateY(30%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeOutBottom {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    100% {
        opacity: 0;
        transform: translateY(30%)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-70%)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    100% {
        opacity: 0;
        transform: translateX(-70%)
    }
}