.u-vimeo-player {
	position: relative;
	width: 100%;
	max-width: $max-content-width;

	&__video-wrapper {
		padding-top: 56.3%;
		position: relative;
		margin: 0;
	}

    iframe {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
	}
}

.u-vimeo-player__cookie-hint {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 90%;
    width: 90%;
    position: absolute;
    top: 5%;
    left: 5%;
    background: #2b2b2b;
    color: #ffffff;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 16px;
    text-align: center; 
}
.u-vimeo-player__cookie-hint a {
    color: #d6d85f;
	margin: 16px 0; 
}