$accordion--color: black!default;
$accordion--header-border-color: black!default;
$accordion--subinfo-color: rgb(237, 237, 245)!default;
$accordion--background: #f4f4f4;

.u-accordion {
	margin-bottom: 2.5rem;
	background-color: $accordion--background;
	color: $c-black;
	max-width: $max-content-width;

	&__icon {
		width: 1rem;
		height: 1rem;
		position: absolute;
		top: 50%;
		right: 0.9375rem;
		transition: all .3s;
		transform: translateY(-50%) rotate(0);

		path {
			transition: all .3s;
			color: black;
		}

		&--minus {
			opacity: 0;
		}

		.is-open > .u-accordion__header & {
			transform: translateY(-50%) rotate(180deg);
	
			&--plus {
				opacity: 0;
			}
			&--minus {
				opacity: 1;
			}
		}
	}
	
    &__header {
		appearance: none;
		border-width: 0;
		display: flex;
		justify-content: space-between;
		margin: 0;
		width: 100%;
		padding: 1.25rem 3.125rem 1.25rem 1.25rem;
		background-color: $c-primary;
		position: relative;

        &:focus,
        &:not([disabled]):hover {
            background: rgba($c-primary,.5);
            color: $accordion--color;
			transition: 0;
			cursor: pointer;
		}

		&:focus {
			outline: 0.0625rem dotted rgba(0,0,0,.2);
		}

		.is-open & {
			border-bottom-color: #dddddd;
		}
    }

    &__headline {
        flex-grow: 1;
		text-align: left;
		color: #000000;
		font-size: 1rem;
		line-height: 1.3;
		text-transform: none;
    }

    &__content {
		padding: 1.25rem;
		font-size: 1rem;
		line-height: 1.4;
    }

	&--style-clean-big {
		border: 0;
		padding: 0;

		& + & {
			margin-top: 1.875rem;
		}

		.u-accordion__content {
			padding-left: 0;
			padding-right: 0;
		}

		.u-accordion__header {
			margin: 0.625rem 0;
			padding: 0.625rem 0;
		}

		.u-accordion__headline {
			font-weight: normal;
			font-size: 1.25rem;
			line-height: 2.1875rem;

			@include media-breakpoint-up(md) {
				font-size: 2.5rem;
				line-height: 3.6875rem;
			}
		}

	}

}