.u-download {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-width: 17.5rem;

    &__color {
        height: 0.875rem;
        width: 0.5rem;
        display: inline-block;
        border-radius: 0.125rem;
        margin-right: 0.375rem;
    }

    &__title {
        vertical-align: middle;
        flex-grow: 1;
        margin-right: 0.5rem;
    }

    &__link {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: rgba($c-white, .7);
        font-weight: 600;
        margin: 1.25rem 0;
    }

    &__icon {
        background-image: url($imagePath + "/pdf.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 2rem;

        &:after {
            content: "";
            padding-top: 120%;
            display: inline-block;
        }
    }

    &__item {

    }
}