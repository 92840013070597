$min-content-width: 23.4375rem; // 375px
$max-content-width-px: 1080px; // 1080px
$max-content-width: 67.5rem; // 1080px

$grid-breakpoints: (
  xxs: 0,
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: $max-content-width-px,
  xl: 1200px,
  xxl: 1440px
);