.u-teaser {
    font-size: 1rem;

    &__headline-link {
        color: $c-primary;
        text-decoration: none;
        margin-bottom: .625rem;
        display: inline-block;
        margin-right: 8px;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__kicker {
        color: $c-primary;
        text-transform: uppercase;
        font-size: 1.25rem;
        letter-spacing: 0.0625rem;
        margin-bottom: 0.75rem;
        display: block;
    }

    &__details-link {
        padding: 3px 7px;
        line-height: 1;
        font-size: 10px;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: .625rem;
    }
}

.u-teaser + .u-event-teaser,
.u-event-teaser + .u-teaser,
.u-teaser + .u-teaser {
    margin-top: 1.25rem;
    border-top: 0.0625rem solid $c-primary;
    padding-top: 1.25rem;
}