html {
  height: 100%;
  font-family: 'Open Sans', Arial, sans-serif;
}

body {
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

// show but disable scrollbar
body.has-open-menu {
  position: fixed; 
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  outline: 0;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

a:hover {
  color: $c-primary;
}

hr {
  background: $c-black;
  width: 2.375rem;
  height: 0.1875rem;
  display: block;
  border-radius: 1.25rem;
  margin-top: .8em;
  margin-bottom: .8em;
  margin-left: 0;
  margin-right: auto;
  border: 0;
}

.no-selection * {
  user-select: none;
}

.u-header, 
.u-page-wrapper {
  font-size: 0.875rem;
  line-height: 1.3;
}

.u-page-wrapper {
  background: $c-black;
  color: $c-white;
}

.clearfix:after  {
  content: "";
  display: table;
  clear: both;
}

.u-clear-both {
  clear: both;
  display: block;
}