.u-teaser-list {
    margin: 0 (-1.875rem);
    
    @include media-breakpoint-up(lg) {
        margin: 0 (-1.875rem);
    }

    &--style-download,
    &--style-download-with-max-size  {
        .u-teaser__image img {
            object-position: left;
            object-fit: contain;
        }

        .u-teaser__headline {
            color: #888888;
            font-size: 12pt;
            font-weight: normal;
            margin-top: 1.875rem;
        }
        
        .u-teaser__link-text {
            color: #c5401e;
            font-size: 12pt;
            font-weight: bold;
            margin-top: 0.3125rem;
            display: block;
        }
    }

    &--style-download-with-max-size  {
        .u-teaser__image img {
            max-width: 26.875rem;
            max-height: 25rem;
        }
    }
}

.u-teaser {
    display: block;
    text-decoration: none;
    
    &__wrapper {
        padding: 0 0.9375rem;
        margin-top: 1.875rem; 
        
        @include media-breakpoint-up(md) {
            width: 50%;
            float: left;
            padding: 0 1.875rem;
            margin-top: 3.75rem; 

            &:nth-child(2n + 1) {
                clear: both;
            }
        }
    }

    &__headline {
        font-size: 1.625rem;
        color: $c-primary;
    }

    &__text {
        color: #999999;
        font-size: 0.875rem;
        line-height: 1.7em;
    }
}

/**
 * SPACES
 */

.u-teaser__headline + .u-teaser__text,
.u-teaser__image + .u-teaser__text {
    margin-top: 2.5rem;
}

.u-teaser__headline + .u-teaser__image {
    margin-top: 2.5rem;
}