/**
 * CONTACT FORM
 */
.u-warning,
.wpcf7-validation-errors.wpcf7-response-output,
.wpcf7-mail-sent-ng.wpcf7-response-output {
    margin: 1.875rem 0 0;
    border: 0;
    background: #c5830b;
    padding: 1.875rem 1.875rem 1.875rem 4.375rem;
    position: relative;

    &:before {
        content: "!";
        position: absolute;
        width: 1.875rem;
        background: #d58e0e;
        height: 1.875rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
        top: 50%;
        left: 1.25rem;
        transform: translateY(-50%);
    }
}

span.wpcf7-not-valid-tip {
    background: #d11a1a;
    color: white;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
}

.wpcf7-text,
.wpcf7-textarea {
    width: 100%;
    margin-top: 0.25rem;
}

.wpcf7-textarea {
    max-width: 100%;
    min-width: 100%;
}

.wpcf7-form label {
    font-size: 0.875rem;
}

/**
 * CONCTACT
 */

.u-page--contact .your-email, 
.u-page--contact .your-name {
    @include media-breakpoint-up(md) {
        width: 50%;
        display: block;
    }
}

.u-contact-info {
    font-size: 1rem;
    padding: 1.25rem 0 3.125rem;
    line-height: 1.5;
    @include media-breakpoint-up(md) {
        width: 50%;
        float: right;
        padding: 1.25rem 3.125rem;
    }
}

/**
 * SPACES
 */

p + div.wpcf7 {
    margin-top: 2rem;
}