$button-hover-duration: .3s;

%button-default {
    text-decoration: none;
    font-weight: bold;
    background-color: $c-white;
    color: $c-black;
    line-height: 2.13;
    -webkit-appearance: none;
    appearance: none;
    border: 0.0625rem solid $c-primary;
    border-radius: 0.125rem;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;

    transition: border-color $button-hover-duration, background-color $button-hover-duration;

    text-align: center;
    padding: 0.25rem 2.5rem;
}

%button-primary {
    color: $c-black;
    border-color: $c-primary;
    background-color: $c-primary;

    &:hover {
        color: $c-black;
    }
}

button,
[type="button"],
[type="submit"],
.wpcf7-submit
.u-button {
    @extend %button-default;
}

.u-button--style-primary,
.wpcf7-submit {
    @extend %button-primary;
}