.u-picture img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

picture.u-picture {
    display: block;
    font-size: 0;
}

/**
 * SPACES
 */
