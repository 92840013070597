%headline_default {
    line-height: 1;
    font-weight: bold;
    margin: 0;
}

h1 {
    @extend %headline_default;
}

h2 {
    @extend %headline_default;
}

h3 {
    @extend %headline_default;
}

h4 {
    @extend %headline_default;
}

h5 {
    @extend %headline_default;
}

h6 {
    @extend %headline_default;
}

/**
 * SPACES
 */