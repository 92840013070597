$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-dot-color: $c-primary !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/**
 * SAME HEIGHT FIX
 */

.slick-track{
    display: flex;

    .slick-slide{
        display: flex;
        height: auto;
        align-items: center; //optional
        justify-content: center; //optional
    }
}


.slick-slide > div {
    width: 100%;
    height: 100%;
}

/**
 * DOTS
 */

.slick-dots {
    position: absolute;
    bottom: 20px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 10px;
                height: 10px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                border-radius: 50%;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}



.u-slider {
    &__item {
        position: relative;
        min-height: 100%;
        display: block;
    }

    &__item-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        picture {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: top left;
        }
    }

    &__item-content {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5vw;
        opacity: 0;
        overflow: hidden;
        flex-direction: column;

        .u-picture {
            display: inline-block;
        }

        .slick-active & {
            opacity: 1;
            animation: fadeInBottom 1s 1 cubic-bezier(.77,0,.175,1);
        }
    }

    &__prev,
    &__next {

        background: none;
        padding: 0;
        font-size: 0;
        margin: .3vw 0;
        border: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        svg {
            position: relative;
            width: 1.5vw;
            height: 1.5vw;
        }

        path {
            fill: $c-primary;
        }
    }

    &__prev {
        left: 1vw;
    }

    &__next {
        right: 1vw;
    }
}

.u-slider--style-default {



}


/**
 * SPACES
 */


 
.u-slider__item-content > .u-button + .u-button,
.u-slider__item-content > .u-picture + .u-button {
    margin-top: 1.25rem;
}