/* BRAND COLORS */

$c-primary:     rgb(196,214,0);     // versah green
$c-secondary:   rgb(90,98,0);       // avocado

$c-black:       rgb(0,0,0);         // #000000
$c-white:       rgb(255,255,255);   // #ffffff

$c-gray:        rgb(88,89,91);     // cool gray

/* OTHER COLORS */

$c-gray-22: #222222;
$c-gray-66: #666666;