input[type="tel"],
input[type="email"],
input[type="password"],
input[type="text"],
textarea {
    background-color: rgba(185,193,68,0.89);
    border: 0.0625rem solid rgba(185,193,68,0.89);
    color: white;
    padding: 1rem;
    font-size: 0.875rem;
}