.u-event-teaser {
    font-size: 1rem;

    & + & {
        margin-top: 1.25rem;
        border-top: 0.0625rem solid $c-primary;
        padding-top: 1.25rem;
    }

    &__date-and-locations {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__date-and-location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0;
        flex-wrap: wrap;
        max-width: 25rem;
    }

    &__details-link,
    &__shop-link {
        padding: 0.25rem 0.5rem;
        line-height: 1;
        font-size: 0.75rem;
    }

    &__details-link {
        align-self: flex-end;
        margin-top: 0.625rem;
        transform: translateY(-4px);
    }

    &__headline-link{
        color: $c-primary;
        text-decoration: none;
        margin-bottom: 0.625rem;
        display: block;
        width: 100%;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__speaker-picture {
        width: 17%;
    }

    &__speaker-picture img {
        width: 100%;
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            padding: 0 1.25rem;
            width: 51%;
        }
        
        @include media-breakpoint-up(xl) {
            padding: 0 1.25rem;
            width: 51%;
        }
    }

    &__dates {
        margin-top: 1.875rem;
        
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            margin-top: 0;
        }

        @include media-breakpoint-up(xl) {
            width: 32%;
            margin-top: 0;
        }
    }

    &__location {
        flex-grow: 1;
    }

    &__location, 
    &__date {
        padding: 0 0.625rem 0 0;
        
        @include media-breakpoint-up(md) {
            padding: 0 0.625rem;
        }
    }

    &__speaker-wrapper {
        display: block;
        align-self: flex-end;
    }
    
    &__speaker {
        display: inline-flex;
        align-items: center;
        margin-top: 0.625rem;
        margin-right: 1.25rem;

        img {
            width: 6.25rem;
            border-radius: 0.1875rem;
            margin-right: 0.625rem;
        }
    }

    .u-event-teaser__speaker-label {
        display: block;
        margin-top: .625rem;
    }

    &--without-picture {
        .u-event-teaser__details {
            padding-left: 0;
        }

        .u-event-teaser__details {
            @include media-breakpoint-up(md) {
                width: 50%;
            }

            @include media-breakpoint-up(xl) {
                width: 68%;
            }
        }
    }

    &__kicker {
        color: $c-primary;
        text-transform: uppercase;
        font-size: 1.25rem;
        letter-spacing: 0.0625rem;
        margin-bottom: 0.75rem;
        display: block;
    }
}