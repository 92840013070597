.u-testimonial--style-teaser {
    padding: 0 1.875rem;
    float: left;
    text-align: center;
    margin-top: 1.875rem;
    width: 100%;
    
    @include media-breakpoint-between(md, lg) {
        width: 50%;

        &:nth-child(2n + 1) {
            clear: both;
        }
    }
    @include media-breakpoint-up(xl) {
        width: 25%;

        &:nth-child(4n + 1) {
            clear: both;
        }
    }

    .u-testimonial__name {
        color: #333;
        font-size: 1.125rem;
        margin-bottom: 0.625rem;
        display: block;
        min-height: 2.6em;
    }

    img {
        width: 5.625rem;
        height: 5.625rem;
        border-radius: 0.75rem;
        margin-bottom: 0.75rem;
    }

    .u-testimonial__text {
        color: #666;
        margin-bottom: 0.75rem;
    }

    .u-testimonial__text:before,
    .u-testimonial__text:after {
        content: "\"";
    }

    .u-testimonial__link {
        color: #2ea3f2;
        text-decoration: none;
    }
}

.u-testimonial--style-full {
    margin-top: 1.5625rem;

    img {
        float: left;
        width: 5.625rem;
        height: 5.625rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        border-radius: 0.75rem;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .u-testimonial__name {
        margin-bottom: 0.75rem;
        display: inline-block;
    }
}