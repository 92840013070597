
$header-width-desktop: 14.0625rem;
$header-height-mobile: 4.125rem;

/**
 * MOBILE HEADER
 */
@include media-breakpoint-down(md) {
/**
     * CONTENT POSITIONING
     */

    body {
        padding-top: $header-height-mobile;
    }

    .u-page-wrapper {
        min-height: calc(100% - #{$header-height-mobile});
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .u-header {
        position: absolute;
        top: 0;
        left: 0;
        height: $header-height-mobile;
        width: 100%;
        z-index: 99999;
    }

    /**
     * HEADER STYLES
     */

    .u-header {
        box-shadow: 0 0 0.4375rem rgba($c-black,.1);
        background-color: #2b2b2b;
        font-weight: 500;
        line-height: 1.4375rem;

        &__logo {

            height: 100%;
            display: flex;
            position: relative;
            width: 6.875rem;
            justify-content: center;
            align-items: center;

            figcaption {
                display: none;
            }

            .u-picture {
                display: flex;
            }
        }

        &__inner {
            display: flex;
            height: 100%;
            width: 80%;
            margin: auto;
            justify-content: space-between;
            align-items: center;
        }
    }

    /**
     * MENU TOGGLER
     */

    .u-header__menu-toggle {
        padding: 0.75rem 0;
        border: 0;
        background: none;
        appearance: none;

        span {
            position: relative;
            display: block;
            width: 1.25rem;
            height: 0.125rem;
            background: $c-primary;
            transition: all .2s ease-in-out;
            
            &:before, 
            &:after {
                left: 0;
                position: absolute;
                background: $c-primary;
                content: '';
                width: 1.25rem;
                height: 0.125rem;
                transition: all .2s ease-in-out;
            }
            
            &:before {
              top: -0.5rem;
            }
            
            &:after {
              top: 0.5rem;
            }
          }
    }
    .u-header--menu-open .u-header__menu-toggle {
        span {
            background: transparent;  

            &:before, 
            &:after {
                background: $c-white;
            }
            
            &:before {
              transform: rotate(45deg) translate(0.3125rem, 0.375rem);
            }
            
            &:after {
              transform: rotate(-45deg) translate(0.3125rem, -0.375rem);
            }
          }
    }

    /**
     * MENU
     */

    .u-header__menu {
        position: absolute;
        top: 4.125rem;
        background: #2b2b2b;
        width: 80%;
        left: 10%;
        border-top: 0.1875rem solid $c-primary;
        padding: 5% 0;
        z-index: 9999;
        opacity: 1;
        display: none;
    }

    .u-header__menu-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .u-header__menu-item {
        padding: 0;
    }

    .u-header__menu-link {
        color: rgba($c-white, .7);
        text-decoration: none;
        line-height: 1.3;
        display: inline-block;
        font-size: 0.875rem;
        padding: 0.625rem 10%;
        display: block;

        &:hover {
            background: #1f1f1f;
            color: rgba($c-white, .7);
        }
    }

    /**
     * SEARCH
     */

    .u-header__search {
        margin-left: auto;
        margin-right: 1.875rem;
    }

    .u-header__search-button,
    .u-header__search-close-button {
        appearance: none;
        border: 0;
        background:0;
        padding: 0;
        text-align: left;
        line-height: 1;

        svg {
            fill: $c-white;
            transition: transform 1s, opacity 1s;
        }
    }

    .u-header__search-button {
        width: 100%;
        z-index: 99;
        display: block;

        svg {
            margin-bottom: -0.125rem;
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .u-header__search-close-button svg {
        width: 1.5rem;
        height: 1.5rem;
    }
    
    .u-header__search-outer {
        overflow: hidden;
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        right: 10%;
        width: calc(80% - 8.125rem);
    }

    .u-header__search-form {
        opacity: 0;
        display: flex;
        z-index: 0;
        flex-grow: 1;

        input {
            border: none;
            color: #fff;
            background: #1f1f1f;
            flex-grow: 1;
            width: auto;
            min-width: 0;
            max-width: none;
            outline: none;
            padding: 0.625rem 1.25rem;
            margin: 0.625rem;
            min-height: 2.875rem;

            &::placeholder {
                color: #fff;
            }
        }
    }
    
    .u-header--menu-closed {

    }
    
    .u-header--search-open {

        .u-header__menu-toggle,
        .u-header__search-button {
            opacity: 0;
            animation: fadeOutBottom 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 0;
        }

        .u-header__search-form {
            opacity: 1;
            animation: fadeInTop 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 99;
        }

        .u-header__search-outer {
            transform: translateY(0);
            height: 100%;
            display: flex;
            justify-content: flex-end;
            z-index: 99;
        }

    }
    
    .u-header--search-closed {

        .u-header__menu-toggle,
        .u-header__search-button {
            opacity: 1;
            animation: fadeInBottom 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 99;
        }

        .u-header__search-form {
            opacity: 0;
            animation: fadeOutTop 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 0;
        }
        
        .u-header__search-outer {
            transform: translateY(-100%);
            z-index: 0;
            transition-delay: 1s;
        }

    }
}

/**
 * DESKTOP HEADER
 */

@include media-breakpoint-up(lg) {

    /**
     * CONTENT POSITIONING
     */

    body {
        padding-left: $header-width-desktop;
    }

    .u-page-wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .u-header {
        position: fixed;
        top: 0;
        left: 0;
        width: $header-width-desktop;
        height: 100%;
        z-index: 99999;
    }

    /**
     * HEADER STYLES
     */

    .u-header {
        padding: 2.5rem 1.875rem;
        box-shadow: 0 0 0.4375rem rgba($c-black,.1);
        background-color: #2b2b2b;
        font-weight: 500;
        line-height: 1.4375rem;
        overflow: hidden;
        overflow-y: auto;

        &__logo {
            figcaption {
                display: none;
            }
        }
    }

    /**
     * MENU
     */

    .u-header__menu {
        // overwrite JS hide for mobile screens
        display: block!important;
        margin-top: 2.5rem;
    }

    .u-header__menu-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .u-header__menu-item {

        & + & {
            margin-top: 0.5rem;
        }

    }

    .u-header__menu-toggle {
        display: none;
    }

    .u-header__menu-link {
        color: rgba($c-white, .7);
        text-decoration: none;
        line-height: 1.3;
        display: inline-block;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.0625rem;

        .u-header__menu-item--current & {
            color: $c-white;
        }

        &:hover {
            opacity: .7;
        }
    }

    /**
     * SEARCH
     */

    .u-header__search {
        margin-top: 1.875rem;
    }

    .u-header__search-button,
    .u-header__search-close-button {
        appearance: none;
        border: 0;
        background:0;
        padding: 0;
        text-align: left;
        line-height: 1;

        svg {
            width: 0.875rem;
            height: 0.875rem;
            fill: rgba(255,255,255,.7);
            transition: transform 1s, opacity 1s;
        }
    }

    .u-header__search-button {
        width: 100%;
        z-index: 99;
        display: block;
    }

    .u-header__search-button svg {
        margin-bottom: -0.125rem;
    }

    .u-header__search-close-button svg {
        transform: scale(.5);
    }

    .u-header__search-outer {
        overflow: hidden;
        position: relative;
        top: 0;
        width: 100%;
        transform: translate(-1000%, -100%);
    }

    .u-header__search-form {
        opacity: 0;
        display: flex;
        z-index: 0;

        input {
            border: none;
            color: #fff;
            background: 0 0;
            flex-grow: 1;
            width: auto;
            min-width: 0;
            max-width: none;
            outline: none;
        }
    }
    
    .u-header--search-open {

        .u-header__search-button {
            opacity: 0;
            animation: fadeOutRight 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 0;
        }

        .u-header__search-button svg {
            opacity: 0;
            transform: scale(.5);
        }

        .u-header__search-close-button svg {
            opacity: 1;
            transform: scale(1);
        }

        .u-header__search-form {
            opacity: 1;
            animation: fadeInLeft 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 99;
        }
        .u-header__search-outer {
            transform: translateX(0);
            transform: translate(0%, -100%);
        }
    }
    
    .u-header--search-closed {
        .u-header__search-button {
            opacity: 1;
            animation: fadeInRight 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 99;
        }
        
        .u-header__search-button svg {
            opacity: 1;
            transform: scale(1);
        }

        .u-header__search-close-button svg {
            opacity: 0;
            transform: scale(.5);
        }
        
        .u-header__search-form {
            opacity: 0;
            animation: fadeOutLeft 1s 1 cubic-bezier(.77,0,.175,1);
            z-index: 0;
        }
        .u-header__search-outer {
            transform: translate(-1000%, -100%);
            transition-delay: 1s;
        }
    }
}