%paragraph_default {
    font-size: 1rem;
}

%paragraph_spaces_default {
    & + & {
        margin-top: 1.58em;
    }
}

p {
    @extend %paragraph_default;
    @extend %paragraph_spaces_default;

    img {
        max-width: 100%;
        height: auto;
    }
}

/**
 * WORDPRESS
 */
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignleft {
	float: left;
	margin: 0.5em 1em 0.5em 0;
}

.alignright {
	float: right;
	margin: 0.5em 0 0.5em 1em;
}

/**
 * SPACES
 */

.u-headline + p,
h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
    margin-top: 1.875rem;
}