.u-youtube-player {
	position: relative;

	&__video-wrapper {
		padding-top: 56.3%;
		position: relative;
		margin: 0;
	}

    iframe {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
	}
}