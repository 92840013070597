.u-footer {

    nav:after {
        content: "";
        display: table;
        clear: both;
    }


    &__top {
        background: $c-gray-22;
        color: $c-white;
        padding: 0;

        .u-section__inner {
            padding: 10% 0;

            @include media-breakpoint-up(md) {
                padding: 60px 0;
            }
        }
        
        a {
            color: $c-white;
            text-decoration: none;
        }
    }

    &__bottom {
        background: $c-black;
        color: $c-gray-66;
        padding: 0;

        .u-section__inner {
            min-height: 3.125rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 0.25rem 0 1rem 0;
            text-align: center;
        }

        &.u-section {
            margin: 0;
        }

        nav {
            width: 100%;
            text-align: center;
        }

        a {
            color: #666;
        }

        .u-footer__menu-item {
            display: inline-block;
        }

        .u-footer__menu-item + .u-footer__menu-item:before {
            content: "";
            margin: 0 .5rem;
            font-size: .7rem;
            border-left: 0.0625rem solid #666;
        }

        .u-footer__newsletter-submit {
            color: white;

            h3 {
                margin-top: 2rem;
                font-size: 15pt;
            }

            button {
                background-color: #c4d600;
                width: 22rem;
                font-size: 12pt;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

        }

    }

    &__menu-item-group {
        width: 25%;
        float: left;
    }
    
    &__menu-subitems {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    &__menu-item {
        margin: 0.5rem 0;
        display: block;
    }
}