.u-event-details {

    margin-top: 2.5rem;
    margin-bottom: 5rem;

    &__headline {
        color: $c-primary;
        margin-bottom: 2.5rem;
    }
    
    &__content-wrapper {
        display: flex;
        flex-direction: column-reverse;

        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
        }
    }
    
    
    &__content {
        @include media-breakpoint-up(lg) {
            width: 70%;
            padding-right: 3.75rem;
        }
    }

    /**
     * SIDEBAR
     */

    &__sidebar {
        margin-top: 2.5rem;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            width: 30%;
        }
    }
    
    &__sidebar-inner > div {
        padding: 1.25rem;
        background: #2b2b2b;
        border-radius: 0.125rem;
    }

    &__sidebar-inner > div + div {
        border-top: 0.0625rem solid $c-primary;
    }
    
    &__sidebar strong {
        display: block;
        margin-bottom: 0.3125rem;
        text-transform: uppercase;
        font-size: 0.75rem;
    }
    
    &__dates-and-locations {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    &__date-and-location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0;
        flex-wrap: wrap;
    }

    &__link {
        float: right;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    /**
     * GALLERY
     */
    &__gallery-item {
        width: 50%;
        display: block;
        float: left;
        padding: 1rem;
        text-decoration: none;
        color: $c-white;
    }
    
    &__gallery-item img {
        width: 100%;
    }

    /**
     * SPEAKER
     */

    &__speaker-image {
        float: right;
        border-radius: 50%;
        display: block;
        overflow: hidden;
        margin-bottom: 1rem;
        margin-left: 1rem;
        width: 9.375rem;
        height: 9.375rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__gallery {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    
    &__speaker-name {
        font-size: 1.25rem;
        margin-bottom: 0.625rem;
        display: block;
    }
    
    &__speaker + &__speaker {
        margin-top: 2.5rem;
    }

    &__speaker-wrapper {
        margin-top: 3.125rem;
    }

}

.fancybox-overlay {
    z-index: 100000;
}